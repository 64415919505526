import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import React from "react";
import '../../fonts/font.css';
import '../../styles/style.scss';
import mainLogo from "../../images/dohack-main-logo.png";
import {graphql} from "gatsby";
import TeamCard from "../../components/team-card/TeamCard";
import Scroll from "../../components/navbar/Scroll";

const IndexPage = (data) => {
    const teamMembers = ["Silas Mahler", "Christoph Weinzierl", "Johannes Teklote", "Igor Besel", "Marius Wagner", "Antonia Schmidt", "Jannick Faber", "Jonas Denis"];
    const team = data.eventData.allContentfulTeamMember.edges.filter(node => teamMembers.includes(node.node.name)).map(node => node.node);
    return (
        <div>
            <Navbar currentPage="">
                <Scroll type="id" target="#about" text="About"/>
                <Scroll type="id" target="#team" text="Team"/>
            </Navbar>

            <header className="silhouette has-static-nav">
                <div className="container">
                    <div className="header content content-col">
                        <div className="item">
                            <img className="header__logo" src={mainLogo} alt="DO!Hack Logo"/>
                        </div>
                        <div className="item">
                            <h1 className="header__date header__date--danger">DO!Hack 2020 abgesagt!</h1>
                        </div>
                    </div>
                </div>
            </header>

            <section className="bg bg-dark">
                <div className="container scrollSpy" id="about">
                    <h1 className="title">Information</h1>
                    <div className="content about__content">
                        <div className="about__text item item-2">
                            <p>Auf Grund der aktuellen gesundheitlichen Situation müssen wir DO!Hack 2020 leider absagen. Wir planen aber schon den nächsten DO!Hack für Anfang 2021.</p>
                            <p>Mehr Informationen bekommt ihr über unsere Social Media Kanäle. Bleibt zu Hause und vor allem bleibt gesund! Wir freuen uns, euch bei DO!Hack 2021 zu sehen!</p>
                            <p>Bis es soweit ist, guckt euch doch noch mal die Aftermovies aus den letzten Jahren an und erinnert euch an die gute Zeit! ;)</p>
                        </div>
                        <div className="item item-2 about__video-container">
                            <iframe className="about__video" title="Aftermovie" src="https://www.youtube.com/embed/GGT0WFZ48Os"></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg bg-light-grey">
                <div className="container scrollSpy" id="team">
                    <h1 className="title">Team</h1>
                    <div className="content team__content">
                        {team.map(member =>
                            <TeamCard key={member.name} data={member} links={['GitHub', 'GitLab', 'Twitter']} imageURL={member.image.localFile.childImageSharp.fluid.src}/>
                        )}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default ({data}) => {
    return (<IndexPage eventData={data} />);
};

export const data = graphql`
    query DeCoHost {
        allContentfulTeamMember(sort: {fields: name}, filter: {node_locale: {eq: "de"}}) {
            edges {
                node {
                    image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    src
                                }
                            }
                        }
                    }
                    name
                    links {
                        name
                        url
                        encoded
                    }
                }
            }
        }
    }
`;
